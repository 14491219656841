/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Project } from "../project";
import { Carousel } from "../carousel";

export const HomeProjectsComingSoon = ({ projects = [] }) => {
  return (
    <Carousel
      infinite={false}
      centerMode={true}
      slidesToShow={1}
      initialSlide={2}
      totalSlides={projects.length}
    >
      {projects.map((project) => (
        <div key={project.sys.id} className="mx-2 mb-8">
          <Project project={project} />
        </div>
      ))}
    </Carousel>
  );
};
