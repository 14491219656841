/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import { HomeEnd } from "../components/page-sections/home-end";
import { HomeProjects } from "../components/page-sections/home-projects";
import { HomeHighlights } from "../components/page-sections/home-highlights";
import { HomeSkills } from "../components/page-sections/home-skills";
import { PageLoader } from "../components/page-loader";

export const HomeBelowTheFold = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          "sys.id[in]": ["4dlGbXqDjK9JILF5pmMNEp"],
          include: 2,
          limit: 1,
        });

        setData(response.items[0].fields);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data from Contentful:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const hash = window.location.hash;

    if (hash.startsWith("#")) {
      const target = document.querySelector(hash);

      if (target) {
        target.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [data]);

  return (
    <>
      {isLoading && (
        <div className="py-8 flex items-center justify-center mb-32">
          <PageLoader />
        </div>
      )}
      {!isLoading && (
        <>
          <HomeProjects
            projects={
              data.projects?.filter((project) => !project.fields.hide) || []
            }
          />
          <HomeSkills
            skills={data.skills?.filter(
              (skill) => skill.fields.category[0] === "Tools & Technologies"
            )}
          />
          <div className="hidden">
            <HomeHighlights />
          </div>
          <div className="py-10 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ">
            <HomeEnd />
          </div>
          <section className="py-10 section-w-full bg-gradient-to-br from-indigo-500 to-teal-500 text-white">
            <section>
              <div className="section-header">
                <h2 className="text-white">About this Site</h2>
                <p>There&apos;s so much to do and I love it</p>
              </div>
              <div className="bg-white p-4 rounded-md text-slate-800 text-left flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <h4 className="font-bold text-lg">
                        This version of the website is brand spankin&apos; new
                      </h4>
                      <p>
                        I&apos;m just getting started - imagine what else I can
                        do!
                      </p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <h4 className="mt-4 font-bold text-lg">
                        What this site is built with
                      </h4>
                      <p>
                        This website is built using a modern and efficient tech
                        stack, with{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.contentful.com/"
                        >
                          Contentful
                        </a>{" "}
                        as the CMS, React for the frontend framework,{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://tailwindcss.com/"
                        >
                          Tailwind CSS
                        </a>{" "}
                        for responsive styling, and{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://gsap.com/"
                        >
                          GreenSock Animation Platform (GSAP)
                        </a>{" "}
                        to add smooth and dynamic animations. The site is
                        deployed via Vercel for fast and scalable hosting. To
                        speed up development, I utilized a{" "}
                        <a
                          href="https://vercel.com/new/e10as-projects/templates/react/auth0-react"
                          target="_blank"
                          rel="noreferrer"
                        >
                          React/Auth0 template
                        </a>{" "}
                        from Vercel, which also provides secure and seamless
                        user authentication.
                      </p>
                      <p>
                        There&apos;s a lot of exciting updates on the way, so
                        make sure to check back for the latest features and
                        improvements! You can explore the website roadmap to see
                        what&apos;s planned for the near future.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2">
                    <h3>Roadmap</h3>
                    <p>Here&apos;s a list of features to look forward to:</p>
                  </div>
                  <ul className="list-disc ml-4">
                    <li>More animation!</li>
                    <li>
                      More information for projects that are currently available
                    </li>
                    <li>Presentations for Coming Soon projects</li>
                    <li>UI/UX updates</li>
                    <li>Dark/light mode</li>
                    <li>Tests</li>
                    <li>Typescript</li>
                    <li>Accessibility</li>
                    <li>Optimization</li>
                    <li>SEO</li>
                    <li>Caching</li>
                  </ul>
                  <p className="ml-4">...and more!</p>
                </div>
                <div className="flex flex-col gap-2">
                  <h4 className="mt-4 font-bold text-lg">
                    Thank you so much for stopping by!
                  </h4>
                  <p>
                    If there&apos;s something you&apos;d like to see included,{" "}
                    <a href="#contact">send me a message</a>! I&apos;d love to
                    hear from you.
                  </p>
                </div>
              </div>
            </section>
          </section>
          <div className="border-t border-slate-300"></div>
        </>
      )}
    </>
  );
};
