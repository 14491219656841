import React, { useEffect, useState } from "react";
import { Experiences } from "../components/ellen/resume/experiences.js";
import { getResumeResource } from "../services/message.service";
import { PageLayout } from "../components/page-layout";
import { PageLoader } from "../components/page-loader";

export const ResumePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let isMounted = true;

    const getData = async () => {
      const { data, error } = await getResumeResource();

      if (!isMounted) {
        return;
      }

      if (data) {
        setPageData(data);
        setIsLoading(false);
      }

      if (error) {
        setPageData(JSON.stringify(error, null, 2));
      }
    };

    getData();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <PageLayout>
      <div className="page-resume content-layout bg-slate-50">
        {isLoading && <PageLoader />}
        {!isLoading && (
          <>
            <h1 className="sr-only">Resume</h1>
            <div className="page">
              <header className="flex flex-col gap-1">
                <h2>{pageData.full_name}</h2>
                <p className="tagline">{pageData.tag_line}</p>
              </header>
              <section id="summary">
                <h3>Summary</h3>
                <p>{pageData.about}</p>
              </section>
              <section id="experience">
                <h3>Experience</h3>
                <Experiences experiences={pageData.experiences} />
              </section>
              <section id="education">
                <h3>Education</h3>
                <div className="self-stretch flex-col justify-start items-start flex">
                  <h4 className="w-full flex">{pageData.education.college}</h4>
                  <p>{pageData.education.degree}</p>
                </div>
              </section>
              <section id="skills">
                <h3>Skills</h3>
                <div className="self-stretch flex-col justify-start items-start flex gap-2">
                  {pageData.skills.technical_skills.map((skill) => (
                    <div key={skill.id}>
                      <h4 className="w-full flex">{skill.title}:</h4>
                      <p>{skill.description}</p>
                    </div>
                  ))}
                </div>
              </section>
              <section id="achievements">
                <h3>Key Achievements</h3>
                <div className="self-stretch flex-col justify-start items-start flex gap-2">
                  {pageData.achievements.map((achievement) => (
                    <div key={achievement.id}>
                      <h4 className="w-full flex">{achievement.title}:</h4>
                      <p>{achievement.description}</p>
                    </div>
                  ))}
                </div>
              </section>
              <section id="resume">
                <a
                  className="button button--primary button--compact text-white flex justify-center"
                  href="https://jrynlppcbsquzh8t.public.blob.vercel-storage.com/resume-qSy38nTK7Byo0y1piEQ7DBGpnKjWws.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Full Resume
                </a>
              </section>
            </div>
          </>
        )}
      </div>
    </PageLayout>
  );
};
