import React from "react";

export const Skill = ({ skill = {} }) => {
  return (
    <div className="skill-card">
      <div className="flex flex-col items-center gap-4 pb-1">
        {skill.fields.image && (
          <img
            className="h-20 w-auto"
            src={skill.fields.image.fields.file.url}
            alt={skill.fields.longName}
          />
        )}
        <div className="font-bold leading-tight">{skill.fields.longName}</div>
      </div>
    </div>
  );
};
