import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavBarTab } from "./nav-bar-tab";

export const NavBarTabs = () => {
  const { isAuthenticated } = useAuth0();

  // const handleClick = (event) => {
  //   event.preventDefault();

  //   const target = document.querySelector(event.target.hash);

  //   if (target) {
  //     target.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   }
  // };

  return (
    <div className="nav-bar__tabs">
      <a className="nav-bar__tab" href="/#projects">
        Projects
      </a>
      <NavBarTab path="/resume" label="Resume" />
      <a className="nav-bar__tab" href="/#contact">
        Contact
      </a>
      {isAuthenticated && (
        <>
          <NavBarTab path="/protected" label="Protected" />
          <NavBarTab path="/admin" label="Admin" />
        </>
      )}
    </div>
  );
};
