import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

export const MobileNavBarTabs = ({ handleClick }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="mobile-nav-bar__tabs">
      <a className="mobile-nav-bar__tab " href="/#projects">
        Projects
      </a>
      <MobileNavBarTab
        path="/resume"
        label="Resume"
        handleClick={handleClick}
      />
      <a className="mobile-nav-bar__tab " href="/#contact">
        Contact
      </a>
      {isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/protected"
            label="Protected"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/admin"
            label="Admin"
            handleClick={handleClick}
          />
        </>
      )}
    </div>
  );
};
