/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import { HomeHero } from "../components/page-sections/home-hero";
import { PageLayoutHome } from "../components/page-layout-home";
import { HomeBelowTheFold } from "./home-page-below-the-fold";

export const HomePage = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: "homepage",
          include: 2,
          limit: 1,
        });
        setData(response.items[0].fields);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data from Contentful:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <PageLayoutHome>
      <div className="flex flex-col justify-center items-center text-center gap-8 768:gap-20">
        <div
          id="above-the-fold"
          className="min-h-[65vh] max-h-100vh py-8 768:py-20 w-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex flex-col items-center justify-center"
        >
          <HomeHero
            title="Ellen Shimada"
            subtitle="Software Engineer • UI/UX Design Lead"
            tagline="My specialty is to design and build engaging, high-quality digital experiences"
            ctas={data.sectionHero?.fields?.ctas || []}
          />
        </div>
        {!isLoading && (
          <div
            id="below-the-fold"
            className="w-full text-center flex flex-col divide-y -mb-8 md:mb-0"
          >
            <HomeBelowTheFold />
          </div>
        )}
      </div>
    </PageLayoutHome>
  );
};
